import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const getImages = graphql`
  {
    fitfarmLogo: file(
      relativePath: { eq: "fitfarm-omaha-color-text-logo.webp" }
    ) {
      childImageSharp {
        fixed(height: 40, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
const Navbar = () => {
  const imgData = useStaticQuery(getImages)
  return (
    <nav className="nav">
      <div className="nav-logocontainer">
        <Link to="/">
          <Img
            fixed={imgData.fitfarmLogo.childImageSharp.fixed}
            alt="Fitfarm full color logo, Green logo with dark text."
            className="nav-logo"
          />
        </Link>
      </div>
      <input id="nav-toggle" type="checkbox" />
      <label className="nav-container" htmlFor="nav-toggle">
        <div className="nav-button"></div>
      </label>
      <ul className="nav-menu">
        <li>
          <Link
            className="nav-menu__links links-menu type-reg type__black"
            to="/crossfit/"
            activeClassName="links-menu links-menu__active main-type__black"
            partiallyActive={true}
          >
            CrossFit
          </Link>
        </li>
        <li>
          <Link
            className="nav-menu__links links-menu type-reg type__black"
            to="/weightloss-and-nutrition/"
            activeClassName="links-menu links-menu__active main-type__black"
            partiallyActive={true}
          >
            Nutrition
          </Link>
        </li>
        <li>
          {" "}
          <Link
            className="nav-menu__links links-menu type-reg type__black"
            to="/personal-training/"
            activeClassName="links-menu links-menu__active main-type__black"
            partiallyActive={true}
          >
            Personal Training
          </Link>
        </li>
        {/* <li>
          <Link
            className="nav-menu__links links-menu type-reg type__black"
            to="/blog/"
            activeClassName="links-menu links-menu__active main-type__black"
            partiallyActive={true}
          >
            Blog
          </Link>
        </li> */}
        <li>
          <Link
            className="nav-menu__links links-menu type-reg type__black"
            to="/contact-us/"
            activeClassName="links-menu links-menu__active main-type__black"
            partiallyActive={true}
          >
            Contact Us
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
