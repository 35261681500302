import React from "react"
import PropTypes from "prop-types"

import "assets/stylesheets/application.scss"

import Navbar from "components/Nav/Navbar"
import Footer from "components/Footer"

const Layout = ({ children }) => {
  return (
    <div className="main">
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
