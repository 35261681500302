import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import Logo from "assets/images/fitfarm-logo-for-white-background.svg"
import facebook from "assets/images/facebook-square-brands.svg"
import instagram from "assets/images/instagram-square-brands.svg"
import youtube from "assets/images/youtube-square-brands.svg"
const footerImages = graphql`
  {
    crossfitJournal: file(relativePath: { eq: "crossfit-journal-black.webp" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    crossfitKids: file(relativePath: { eq: "crossfit-kids-program.webp" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
const Footer = () => {
  const footerData = useStaticQuery(footerImages)
  return (
    <footer className="footer">
      <img
        src={Logo}
        className="img-background img-background__white"
        alt="FitFarm Logo reversed nearly transparent"
      />
      <div className="footer-grid">
        <div className="footer-grid__links footer-grid__item">
          <p className="type-heavy type-black type-header__footer">
            Quick Links
          </p>
          <ul className="u-mt-2">
            <li className="type-reg type-black type-footer__links">
              <Link to="/crossfit/">CrossFit</Link>
            </li>
            <li className="type-reg type-black type-footer__links">
              <Link to="/weightloss-and-nutrition/">Nutrition</Link>
            </li>
            <li className="type-reg type-black type-footer__links">
              <Link to="/personal-training/">Personal Training</Link>
            </li>
            <li className="type-reg type-black type-footer__social">
              <a
                href="https://www.facebook.com/FitFarmOmaha"
                target="__blank"
                rel="external"
                className="u-mr-xs"
                aria-label="Facebook"
              >
                {" "}
                <img
                  src={facebook}
                  height={40}
                  color="#0f8b3b"
                  alt="green facebook logo"
                />
              </a>
              <a
                href="https://www.instagram.com/fitfarmomaha/?hl=en"
                target="__blank"
                rel="external"
                className="u-mr-xs "
                aria-label="Instagram"
              >
                {" "}
                <img
                  src={instagram}
                  height={40}
                  color="#0f8b3b"
                  alt="green instagram logo"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCO0Bsy9Nz1fVE6-tFpp9u-A"
                target="__blank"
                rel="external"
                aria-label="YouTube"
              >
                {" "}
                <img
                  src={youtube}
                  height={40}
                  color="#0f8b3b"
                  alt="green youtube logo"
                />
              </a>
            </li>

            {/* <li className="type-reg type-black type-footer__links">
              <Link to="/blog/">Blog</Link>
            </li> */}
          </ul>
        </div>
        <div className="footer-grid__contact footer-grid__item">
          {/* <p className="type-heavy type-black type-header__footer">
            Contact Us
          </p>
          <ul className="u-mt-2">
            <li className="type-reg type-black type-footer__links">
              (402) 614-6446
            </li>
            <li className="type-reg type-black type-footer__links">
              fitfarm@fitfarmomaha.com
            </li>
          </ul> */}
          <div className="footer-grid__links footer-grid__item">
            <p className="type-heavy type-black type-header__footer">
              Contact Us
            </p>
            <ul className="u-mt-2">
              <li className="type-reg type-black type-footer__links">
                <a href="tel:(402)-614-6446">(402) 614-6446</a>
              </li>
              <li className="type-reg type-black type-footer__links">
                <a href="mailto:info@fitfarmomaha.com">info@fitfarmomaha.com</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-grid__img footer-grid__item">
          <a
            href="https://www.crossfit.com/certificate-courses/online-kids"
            target="_blank"
            rel="noreferrer"
          >
            <Img
              fixed={footerData.crossfitKids.childImageSharp.fixed}
              alt="Fitfarm female member standing tall after performin a power clean."
              className="footer-img"
              style={{ width: "125px", height: "125px", marginRight: "1rem" }}
            />
          </a>
          <a href="https://www.crossfit.com/" target="_blank" rel="noreferrer">
            <Img
              fixed={footerData.crossfitJournal.childImageSharp.fixed}
              alt="Fitfarm female member standing tall after performin a power clean."
              className="footer-img"
              style={{ width: "250px", height: "125px" }}
            />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
